import React from 'react'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { SwitchStageAction } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/SwitchStage/SwitchStageAction'

interface Props {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
}

export const PublishActions = ({ cycle, stage }: Props) => {
  return (
    <>
      <SwitchStageAction cycle={cycle} stage={stage} />
    </>
  )
}

import { useEffect } from 'react'
import pick from 'lodash/pick'

import {
  PayGroupInterface,
  PayrunPreviewPayloadInterface,
} from '@src/interfaces/payrollV2'
import { useGetSelectors } from '@src/api/selectors'
import { IdAndName } from '@src/interfaces'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'

export const formatNonCompliantToIdAndName = (
  values: PayGroupInterface,
  toIdAndName: (id: number) => IdAndName | null,
) => {
  if (typeof values.pay_date_day === 'number' && toIdAndName(values.pay_date_day)) {
    values.pay_date_day = toIdAndName(values.pay_date_day)
  }
  if (
    typeof values.pay_period_start_day === 'number' &&
    toIdAndName(values.pay_period_start_day)
  ) {
    values.pay_period_start_day = toIdAndName(values.pay_period_start_day)
  }
}

const formatNonCompliantToId = (values: PayGroupInterface) => {
  return {
    ...(values.pay_date_day ? { pay_date_day: values.pay_date_day.id } : null),
    ...(values.pay_period_start_day
      ? { pay_period_start_day: values.pay_period_start_day.id }
      : null),
    // BE expects values in different format than what it actually returns
  } as unknown as Pick<PayGroupInterface, 'pay_date_day' | 'pay_period_start_day'>
}

export const formatValuesToSubmit = (values: PayGroupInterface) => {
  return {
    ...values,
    ...formatNonCompliantToId(values),
  }
}

const payrunScheduleFields = [
  'pay_date_day',
  'pay_frequency',
  'pay_date_schedule',
  'pay_period_start_day',
  'cut_off_date_trigger',
  'cut_off_date_trigger_number_of_days',
  'pay_date_lands_on_weekend',
]

const payrunDefaultFields = {
  number_of_schedules: 12,
  report_submission_date_trigger: {
    id: 'after_pay_cycle_starts',
    name: 'After pay cycle starts',
  },
  report_submission_date_days_after_cycle_ends: 0,
}

export const formatValuesToGetPayrunSchedule = (values: PayGroupInterface) => {
  return {
    ...pick(values, payrunScheduleFields),
    ...formatNonCompliantToId(values),
    ...payrunDefaultFields,
  } as PayrunPreviewPayloadInterface
}

export const useFormatNonCompliantToIdAndName = () => {
  const { values } = useLapeContext<PayGroupInterface>()
  const options = useGetSelectors<IdAndName>(selectorKeys.month_days)

  const toIdAndName = (value: number | null) =>
    options.data?.find(option => option.id === value) || undefined
  const formatNonCompliantIdValue = <K extends keyof PayGroupInterface>(path: K) => {
    const formatFrom = values[path]

    if (typeof formatFrom === 'number' && toIdAndName(formatFrom) !== undefined) {
      values[path] = toIdAndName(formatFrom) as PayGroupInterface[K]
    }
  }

  useEffect(() => {
    formatNonCompliantIdValue('pay_date_day')
    formatNonCompliantIdValue('pay_period_start_day')
  }, [options])
}

import React from 'react'
import { MoreBar, useToggle } from '@revolut/ui-kit'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { stageToButtonPropsMap } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/SwitchStage/constants'
import { SwitchStagePopup } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Popup'
import { useSwitchStage } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useSwitchStage'
import { useSwitchStageStatusPopup } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/SwitchStage/hooks/useSwitchStageStatusPopup'
import { useStageHistory } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useStageHistory'

interface Props {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
}

export const SwitchStageAction = ({ cycle, stage }: Props) => {
  const { label, iconName, disabled } = stageToButtonPropsMap[stage]
  const [isPopupOpen, popupToggler] = useToggle()
  const { showSwitchNextCompleted, showSwitchNextFailed } = useSwitchStageStatusPopup()
  const { next, isLoading } = useSwitchStage(cycle)
  const { update } = useStageHistory(cycle)

  const switchNextStage = async () => {
    try {
      const nextStage = await next()

      if (nextStage !== null) {
        update(nextStage)
      }

      showSwitchNextCompleted(stage)
    } catch (err) {
      showSwitchNextFailed(stage, err)
    } finally {
      popupToggler.off()
    }
  }

  return (
    <>
      <MoreBar.Action
        useIcon={iconName}
        onClick={popupToggler.on}
        pending={isLoading}
        disabled={disabled}
      >
        {label}
      </MoreBar.Action>
      <SwitchStagePopup
        cycle={cycle}
        stage={stage}
        isOpen={isPopupOpen}
        isSwitchPending={isLoading}
        handleConfirm={switchNextStage}
        handleClose={popupToggler.off}
      />
    </>
  )
}

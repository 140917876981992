import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Avatar,
  Box,
  Button,
  Caption,
  Dropdown,
  Flex,
  Footnote,
  HStack,
  Header,
  Icon,
  InputGroup,
  Item,
  Layout,
  StatusPopup,
  TextButton,
  Token,
  Text,
  VStack,
  useDropdown,
  IconButton,
  Spacer,
} from '@revolut/ui-kit'
import * as Sentry from '@sentry/react'

import { ROUTES, WORKSPACES } from '@src/constants/routes'
import {
  getLoginUrlAction,
  logInAction,
  setAuthenticatedAction,
  testLogin,
} from '@src/store/auth/actions'
import { selectAuthenticated, selectUser } from '@src/store/auth/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import { goBack, navigateReplace, navigateTo } from '@src/actions/RouterActions'

import { Route, Switch } from 'react-router-dom'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeForm, { LapeFormInterface, useLapeContext } from '@src/features/Form/LapeForm'
import { emailLoginRequest, sendMagicLink, useLoginSettings } from '@src/api/login'
import { Grid } from '@components/CommonSC/Grid'
import { arrayErrorsToFormError } from '@src/utils/form'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { ERROR_DEFAULT_DURATION, ERRORS } from '@src/constants/notifications'
import MagicLinkInstructionsSidebar from './MagicLinkInstructionsSidebar'
import { pathToUrl } from '@src/utils/router'
import {
  GetRevolutPeopleCaption,
  NonFieldErrors,
  OnboardingLoginCaption,
  saveLoginData,
  VerificationCodeForm,
} from './common'
import { Authenticated } from '@src/store/auth/constants'
import { CredentialsLoginInterface } from '@src/interfaces/auth'
import { setSignupStateCookie, SignupVerificationForm } from '@src/pages/SignUp/common'
import {
  setEmployeeOnboardingV2IdCookie,
  setSignupAuthenticatedCookie,
} from '@src/utils/cookies'
import { InternalUIKitLink } from '@src/components/InternalLink/InternalLink'
import { useWorkspaceContext } from '@src/features/Workspaces/WorkspaceContext'
import { isWorkspacesEnabled } from '@src/utils'

interface OnboardingForm {
  email: string
}

interface EmailPasswordForm {
  email: string
  password: string
}

const OnboardingLoginForm = () => {
  const { isSubmitting, apiErrors, submit } = useLapeContext<OnboardingForm>()
  const [instructionsOpen, setInstructionsOpen] = useState(false)

  return (
    <>
      <LapeNewInput
        name="email"
        label="Email"
        hasError={!!apiErrors.email}
        message={apiErrors.email}
        hideOptional
      />

      <Caption textAlign="center" color={Token.color.greyTone50}>
        Not receiving your magic link? Click{' '}
        <TextButton onClick={() => setInstructionsOpen(true)}>here</TextButton> for help
      </Caption>
      <MagicLinkInstructionsSidebar
        isOpen={instructionsOpen}
        onClose={() => setInstructionsOpen(false)}
      />

      <Layout.ActionsFill>
        <Button onClick={() => submit()} type="submit" pending={isSubmitting} elevated>
          Login
        </Button>
        <GetRevolutPeopleCaption />
      </Layout.ActionsFill>
    </>
  )
}

const onSuccessfulEmailLogin = (
  data: CredentialsLoginInterface,
  dispatchAuthenticated: () => void,
) => {
  saveLoginData(data)
  navigateReplace(pathToUrl(ROUTES.MAIN))
  dispatchAuthenticated()
}

const CredentialsLoginForm = () => {
  const { isSubmitting, apiErrors, submit } = useLapeContext<EmailPasswordForm>()

  const nonFieldErrors = Object.entries(apiErrors)
    .filter(([key]) => key !== 'email' && key !== 'password')
    .map(([, value]) => value)

  return (
    <>
      <InputGroup>
        <LapeNewInput
          name="email"
          label="Email"
          hasError={!!apiErrors.email}
          message={apiErrors.email}
          hideOptional
          autoComplete="username"
        />
        <LapeNewInput
          name="password"
          type="password"
          label="Password"
          hasError={!!apiErrors.password}
          message={apiErrors.password}
          hideOptional
          autoComplete="current-password"
        />
        {/* This causes the form to be submitted on enter */}
        <input type="submit" hidden />
        <NonFieldErrors nonFieldErrors={nonFieldErrors} />

        <Caption color={Token.color.greyTone50} textAlign="center">
          Forgot password?{' '}
          <InternalUIKitLink
            // @ts-expect-error
            to={ROUTES.LOGIN.RESET_PASSWORD}
          >
            Reset password
          </InternalUIKitLink>
        </Caption>
      </InputGroup>

      <Layout.ActionsFill>
        <Button onClick={() => submit()} pending={isSubmitting} elevated>
          Login
        </Button>
        <OnboardingLoginCaption />
        <GetRevolutPeopleCaption />
      </Layout.ActionsFill>
    </>
  )
}

function Login() {
  const isLoggedIn = useSelector(selectAuthenticated)
  const user = useSelector(selectUser)
  const { query } = useQuery()
  const dispatch = useDispatch()
  const workspaceDropdown = useDropdown()

  const { data: loginSettings } = useLoginSettings()

  const workspaceContext = useWorkspaceContext()
  const workspacesEnabled = isWorkspacesEnabled()

  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const [successEmail, setSuccessEmail] = useState<string>()
  const [twoFALoginData, setTwoFALoginData] = useState<CredentialsLoginInterface>()

  useEffect(() => {
    if (query.token) {
      // for testing purposes
      dispatch(testLogin(query.token))
    }
  }, [query])

  useEffect(() => {
    if (loginSettings?.google_sso_enabled) {
      dispatch(getLoginUrlAction())
    }
  }, [loginSettings?.google_sso_enabled])

  const handleClick = () => {
    dispatch(logInAction())
  }

  if (isLoggedIn) {
    Sentry.setUser({ id: `${user.id}` })
    goBack(ROUTES.MAIN)
    return null
  }

  const handleOnboardingLogin = (form: LapeFormInterface<OnboardingForm>) => {
    return sendMagicLink(form.values.email)
      .then(() => {
        setSuccessEmail(form.values.email)
        setSuccessPopupOpen(true)
        return form.values
      })
      .catch(error => {
        const errors = arrayErrorsToFormError<OnboardingForm>(error?.response?.data)
        form.apiErrors = errors

        if (!errors?.email) {
          pushNotification({
            value: ERRORS.UNKNOWN,
            duration: ERROR_DEFAULT_DURATION,
            type: NotificationTypes.error,
          })
        }

        throw error
      })
  }

  const handleEmailLogin = (form: LapeFormInterface<EmailPasswordForm>) => {
    return emailLoginRequest(form.values)
      .then(({ data }) => {
        /** Handle sign up login */
        if (
          data.state === 'company_details' ||
          data.state === 'pending' ||
          data.state === 'preparing'
        ) {
          setSignupStateCookie('company_details', data.token, data.tenant_id)
          navigateTo(ROUTES.SIGNUP.COMPANY_DETAILS)
          return form.values
        }
        if (data.state === 'email_confirmation') {
          setSignupStateCookie('email_confirmation', data.token)
          setTwoFALoginData(data)
          return form.values
        }
        if (data.state === 'waiting_list' || data.state === 'setup_failed') {
          setSignupStateCookie('waiting_list')
          navigateTo(ROUTES.SIGNUP.WAITING_LIST)
          return form.values
        }
        if (data.state === 'suspended' || data.state === 'closed') {
          setSignupStateCookie('suspended_or_closed')
          navigateTo(ROUTES.SIGNUP.SUSPENDED)
          return form.values
        }
        if (data.state === 'active' && data.redirect_url) {
          setSignupAuthenticatedCookie()
          window.location.href = data.redirect_url
          return form.values
        }

        /** Handle onboarding employee login */
        if (data.token_type === 'on_boarding') {
          if (data.employee_onboarding_v2_id && data.employee_id) {
            setEmployeeOnboardingV2IdCookie(String(data.employee_onboarding_v2_id))

            navigateTo(
              pathToUrl(ROUTES.ONBOARDING_V2.START, {
                id: data.employee_id,
                onboardingId: data.employee_onboarding_v2_id,
              }),
            )
          } else {
            navigateTo(pathToUrl(ROUTES.ONBOARDING.START, { id: data.employee_id }))
          }
          return form.values
        }

        /** Handle regular login */
        if (data.two_factor_authentication_enabled) {
          setTwoFALoginData(data)
        } else {
          onSuccessfulEmailLogin(data, () => {
            dispatch(
              setAuthenticatedAction(data.authenticated === Authenticated.authenticated),
            )
          })
        }
        return form.values
      })
      .catch(error => {
        const errors = arrayErrorsToFormError(error?.response?.data)
        form.apiErrors = errors
        throw error
      })
  }

  if (twoFALoginData != null) {
    /** Handle signup flow */
    if (twoFALoginData.state === 'email_confirmation') {
      return <SignupVerificationForm token={twoFALoginData.token} />
    }

    return (
      <VerificationCodeForm
        token={twoFALoginData.token}
        isPhoneNumberVerified={twoFALoginData.is_phone_number_verified}
        onSuccess={() => {
          onSuccessfulEmailLogin(twoFALoginData, () => {
            dispatch(
              setAuthenticatedAction(
                twoFALoginData.authenticated === Authenticated.authenticated,
              ),
            )
          })
        }}
        onCancel={() => setTwoFALoginData(undefined)}
      />
    )
  }

  const googleLoginEnabled = loginSettings?.google_sso_enabled
  const credentialsLoginEnabled = loginSettings?.credentials_authentication_enabled

  return (
    <>
      <Flex flexDirection="column" mt={{ all: '80px', lg: 'auto' }} mb="auto" gap="s-16">
        <Header>
          <Route path={ROUTES.LOGIN.ONBOARDING} exact>
            <Header.BackButton
              onClick={() => goBack(ROUTES.LOGIN.MAIN)}
              aria-label="Back to login"
            />
          </Route>

          <Header.Title>Log in to Revolut People</Header.Title>
        </Header>
        <Switch>
          <Route exact path={ROUTES.LOGIN.MAIN}>
            <Grid gap={16}>
              {workspacesEnabled ? (
                <Box mb="s-16">
                  <Item use="button" {...workspaceDropdown.getAnchorProps()}>
                    <Item.Avatar>
                      <Avatar useIcon="CompanyFilled" />
                    </Item.Avatar>
                    <Item.Content>
                      <Item.Title>{workspaceContext?.workspace}</Item.Title>
                    </Item.Content>
                    <Item.Side>
                      <Icon name="ChevronDown" />
                    </Item.Side>
                  </Item>
                  <Dropdown {...workspaceDropdown.getTargetProps()} fitInAnchor>
                    {workspaceContext?.workspaces
                      .filter(ws => ws !== workspaceContext?.workspace)
                      .map(ws => {
                        const workspaceCompanyName =
                          workspaceContext?.workspaceCompanyNames[ws] || ws
                        const workspaceCompanyIcon =
                          workspaceContext?.workspaceCompanyIcons[ws]

                        return (
                          <Dropdown.Item
                            onClick={() => {
                              workspaceDropdown.toggle(false)
                              window.location.href = `${document.location.origin}/${ws}${ROUTES.LOGIN.MAIN}`
                            }}
                            use="button"
                            py="s-24"
                            key={ws}
                          >
                            <HStack align="center">
                              <Avatar
                                image={workspaceCompanyIcon}
                                useIcon={
                                  workspaceCompanyIcon ? undefined : 'CompanyFilled'
                                }
                              />
                              <Text ml="s-16">{workspaceCompanyName}</Text>
                              <Spacer />
                              <IconButton
                                onClick={e => {
                                  e.stopPropagation()
                                  workspaceContext?.removeWorkspace(ws)
                                }}
                                useIcon="Cross"
                                color={Token.color.red}
                              />
                            </HStack>
                          </Dropdown.Item>
                        )
                      })}
                    <Dropdown.Item
                      onClick={() => {
                        workspaceDropdown.toggle(false)
                        window.location.href = WORKSPACES.MAIN
                      }}
                      useIcon="PlusCircle"
                      use="button"
                      py="s-24"
                    >
                      Add a workspace
                    </Dropdown.Item>
                  </Dropdown>
                </Box>
              ) : null}

              {googleLoginEnabled && (
                <VStack space="s-16">
                  <Button
                    useIcon="LogoGoogle|image"
                    onClick={handleClick}
                    disabled={loginSettings == null}
                    backgroundColor={Token.color.widgetBackground}
                    color={Token.color.foreground}
                  >
                    Log in with Google
                  </Button>
                  {credentialsLoginEnabled && <Footnote>OR</Footnote>}
                </VStack>
              )}
              {credentialsLoginEnabled ? (
                <LapeForm onSubmit={handleEmailLogin}>
                  <CredentialsLoginForm />
                </LapeForm>
              ) : (
                <Layout.ActionsFill>
                  <OnboardingLoginCaption />
                </Layout.ActionsFill>
              )}
            </Grid>
          </Route>

          <Route exact path={ROUTES.LOGIN.ONBOARDING}>
            <LapeForm onSubmit={handleOnboardingLogin}>
              <OnboardingLoginForm />
            </LapeForm>
          </Route>
        </Switch>
      </Flex>

      <StatusPopup
        variant="success-result"
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
      >
        <StatusPopup.Title>We've sent a magic link to {successEmail}</StatusPopup.Title>
        <StatusPopup.Description>
          Check your inbox or spam folder and click the link to access your Revolut People
          account.
        </StatusPopup.Description>
        <StatusPopup.Actions>
          <Button onClick={() => setSuccessPopupOpen(false)} elevated>
            Got it
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>
    </>
  )
}

export default Login

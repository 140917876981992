import React from 'react'
import { Color, ProgressStep, Small, Token, ProgressStepState } from '@revolut/ui-kit'

const getCustomIndicatorColor = (state: ProgressStepState): Color | undefined => {
  switch (state) {
    case 'done':
      return Token.color.teal
    case 'default':
    case 'pending':
    case 'failed':
    default:
      return undefined
  }
}

export interface Props {
  state: ProgressStepState
  highlight: boolean
  title: string
  description?: string
  onClick: () => void
}

export const StageProgressStep = ({
  state,
  highlight,
  title,
  description,
  onClick,
}: Props) => {
  const indicatorColor = getCustomIndicatorColor(state)
  const bg = highlight ? Token.color.grey50_10 : undefined

  return (
    <ProgressStep
      state={state}
      indicatorColor={indicatorColor}
      padding="s-6"
      borderRadius="r6"
      bg={bg}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <ProgressStep.Title>{title}</ProgressStep.Title>
      {description && (
        <ProgressStep.Description>
          <Small>{description}</Small>
        </ProgressStep.Description>
      )}
    </ProgressStep>
  )
}

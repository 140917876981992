import React from 'react'
import {
  AccountSwitcher,
  Avatar,
  IconButton,
  Item,
  Popover,
  Token,
  useDropdown,
} from '@revolut/ui-kit'

import { useGetCompanyLogo, useGetCompanyPreferences } from '@src/api/settings'
import { ROUTES, WORKSPACES } from '@src/constants/routes'
import { useWorkspaceContext } from '@src/features/Workspaces/WorkspaceContext'
import { Environments, env } from '@src/constants/api'

export const TopNavAccountSwitcher = () => {
  const { data: companyLogoData, isLoading: isCompanyLogoLoading } = useGetCompanyLogo()
  const { data: companyPreferencesData, isLoading: isCompanyPreferencesLoading } =
    useGetCompanyPreferences()

  const { toggle, ...dropdown } = useDropdown({
    disabled:
      env === Environments.productionCommercial ||
      env === Environments.productionCommercialRoot,
  })

  const workspaceContext = useWorkspaceContext()

  if (isCompanyLogoLoading || isCompanyPreferencesLoading) {
    return <AccountSwitcher pending />
  }

  return (
    <>
      <AccountSwitcher
        image={companyLogoData?.url || undefined}
        {...dropdown.getAnchorProps()}
      >
        {companyPreferencesData?.brand_name}
      </AccountSwitcher>

      <Popover
        {...dropdown.getTargetProps()}
        title="Revolut People accounts"
        mobileBehaviour="bottom-sheet"
      >
        {workspaceContext?.workspaces?.length ? (
          <Popover.Group>
            {workspaceContext.workspaces
              .filter(workspace => workspace !== workspaceContext?.workspace)
              .map(workspace => {
                const workspaceIcon = workspaceContext?.workspaceCompanyIcons[workspace]
                const workspaceCompanyName =
                  workspaceContext?.workspaceCompanyNames[workspace] || workspace

                return (
                  <Item
                    use="button"
                    onClick={() => {
                      window.location.href = `${document.location.origin}/${workspace}${ROUTES.LOGIN.MAIN}`
                    }}
                    key={workspace}
                  >
                    <Item.Avatar>
                      <Avatar
                        image={workspaceIcon}
                        useIcon={workspaceIcon ? undefined : 'CompanyFilled'}
                      />
                    </Item.Avatar>
                    <Item.Content>
                      <Item.Title>{workspaceCompanyName}</Item.Title>
                    </Item.Content>
                    <Item.Side>
                      <IconButton
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()

                          workspaceContext?.removeWorkspace(workspace)
                        }}
                        useIcon="Cross"
                        color={Token.color.red}
                      />
                    </Item.Side>
                  </Item>
                )
              })}
          </Popover.Group>
        ) : null}
        <Popover.Group>
          <Popover.Item
            use="button"
            useIcon="Plus"
            onClick={() => {
              window.location.href = WORKSPACES.MAIN
            }}
          >
            Add account
          </Popover.Item>
        </Popover.Group>
      </Popover>
    </>
  )
}

import React, { useState } from 'react'
import { MoreBar, MoreBarSkeleton } from '@revolut/ui-kit'
import { ReviewResult } from '@src/interfaces/CRM'
import {
  createReviewResult,
  invalidateGetReviewResult,
  updateReviewResult,
  useGetReviewResult,
} from '@src/api/CRM'
import {
  useReviewLocationState,
  useReviewNavigation,
} from '@src/pages/Forms/Candidate/CRMReview/utils'
import { useQueryClient } from 'react-query'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useGetCandidateSettings } from '@src/api/settings'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'

const useReviewResult = () => {
  const { reviewId, interviewRoundId } = useReviewLocationState()
  const { data, ...ctx } = useGetReviewResult(reviewId, interviewRoundId)
  const queryClent = useQueryClient()
  const invalidateReviewResult = () => {
    invalidateGetReviewResult(queryClent, reviewId)
  }
  return {
    ...ctx,
    data: data?.results?.[0],
    invalidateReviewResult,
  }
}

type CRMReviewActionsProps = {
  interviewRounds: number[]
}

export const CRMReviewActions = ({ interviewRounds }: CRMReviewActionsProps) => {
  const { data: candidateSettings } = useGetCandidateSettings()
  const { activeAction, setActiveAction } = useCandidateProfileContext()
  const { reviewId, interviewRoundId } = useReviewLocationState()
  const { handleNavigateToNext } = useReviewNavigation(interviewRounds)
  const { data, isLoading: loadingReview, invalidateReviewResult } = useReviewResult()
  const [loading, setLoading] = useState(false)
  const showStatusPopup = useShowStatusPopup()
  const handleReview = async (reviewResult: ReviewResult) => {
    setLoading(true)
    try {
      if (data) {
        await updateReviewResult(reviewId, interviewRoundId, data.id, reviewResult)
      } else {
        await createReviewResult(reviewId, interviewRoundId, reviewResult)
      }
      invalidateReviewResult()
      await handleNavigateToNext()
    } catch (e) {
      showStatusPopup({
        status: 'error',
        title: `There was a error ${
          reviewResult === 'skipped' ? 'skipping' : 'qualifying'
        } candidate`,
        description: getStringMessageFromError(e),
      })
    } finally {
      setLoading(false)
    }
  }
  if (loadingReview) {
    return <MoreBarSkeleton />
  }
  const skipped = data?.review_result?.id === 'skipped'
  const qualified = data?.review_result?.id === 'qualified'
  return (
    <MoreBar>
      <MoreBar.Action
        disabled={skipped}
        pending={loading}
        useIcon="TurboTransfer"
        onClick={() => {
          handleReview('skipped')
        }}
      >
        Skip
      </MoreBar.Action>
      <MoreBar.Action
        disabled={qualified}
        pending={loading}
        useIcon="SocialLike"
        onClick={() => {
          handleReview('qualified')
        }}
      >
        Qualify
      </MoreBar.Action>
      {candidateSettings?.enable_emails && (
        <MoreBar.Action
          useIcon="Envelope"
          onClick={() => {
            if (activeAction?.type === 'sendEngagementEmail') {
              setActiveAction(undefined)
            } else {
              setActiveAction({
                type: 'sendEngagementEmail',
              })
            }
          }}
        >
          Send email
        </MoreBar.Action>
      )}
    </MoreBar>
  )
}

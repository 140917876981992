import React from 'react'
import {
  Avatar,
  Box,
  Caption,
  HStack,
  IconName,
  Status,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import { GradeCalculationMethods } from '@src/interfaces/reviewCycles'
import { getCalibrationMethodTitle } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CalibrationLogic/constants'
import { OptionInterface } from '@src/interfaces/selectors'

interface Props {
  iconName: IconName
  title: string
  description: string
  helpText: string
}

const OptionLabel = ({ iconName, title, description, helpText }: Props) => (
  <HStack ml="s-8">
    <Box mr="s-16">
      <Avatar useIcon={iconName} />
    </Box>
    <VStack>
      <Text>{title}</Text>
      <Caption color={Token.color.greyTone50}>{description}</Caption>
      <Box mt="s-8">
        <Status useIcon="Info">
          <Caption>{helpText}</Caption>
        </Status>
      </Box>
    </VStack>
  </HStack>
)

const lMRecommendationOption: RadioOption<OptionInterface<GradeCalculationMethods>> = {
  value: {
    name: GradeCalculationMethods.LineManagerSuggestion,
    id: GradeCalculationMethods.LineManagerSuggestion,
  },
  label: (
    <OptionLabel
      iconName="Profile"
      title={getCalibrationMethodTitle(GradeCalculationMethods.LineManagerSuggestion)}
      description="The grade given by the Line Manager will be used for the calibration."
      helpText="Use this if you you want to give Line managers the final say about someone’s
          grade."
    />
  ),
}

const fMRecommendationOption: RadioOption<OptionInterface<GradeCalculationMethods>> = {
  value: {
    name: GradeCalculationMethods.FunctionalManagerSuggestion,
    id: GradeCalculationMethods.FunctionalManagerSuggestion,
  },
  label: (
    <OptionLabel
      iconName="Profile"
      title={getCalibrationMethodTitle(
        GradeCalculationMethods.FunctionalManagerSuggestion,
      )}
      description="The grade given by the Functional Manager will be used for the calibration."
      helpText="Use this if you you want to give Functional managers the final say about someone’s
          grade."
    />
  ),
}

const combinedRecommendationsOption: RadioOption<
  OptionInterface<GradeCalculationMethods>
> = {
  value: {
    name: GradeCalculationMethods.PerformanceGradeSuggestion,
    id: GradeCalculationMethods.PerformanceGradeSuggestion,
  },
  label: (
    <OptionLabel
      iconName="BulkSelection"
      title={getCalibrationMethodTitle(
        GradeCalculationMethods.PerformanceGradeSuggestion,
      )}
      description="The grade given by the Line Manager and the Functional Manager will be combined and..."
      helpText="Use this if you..."
    />
  ),
}

const combinedGradesOption: RadioOption<OptionInterface<GradeCalculationMethods>> = {
  value: {
    name: GradeCalculationMethods.PerformanceExtraGradeSuggestion,
    id: GradeCalculationMethods.PerformanceExtraGradeSuggestion,
  },
  label: (
    <OptionLabel
      iconName="BulkSelection"
      title={getCalibrationMethodTitle(
        GradeCalculationMethods.PerformanceExtraGradeSuggestion,
      )}
      description="The grade given by the Line Manager and the Functional Manager will be combined and..."
      helpText="Use this if you..."
    />
  ),
}

const scorecardGradesOption: RadioOption<OptionInterface<GradeCalculationMethods>> = {
  value: {
    name: GradeCalculationMethods.CalculatedGrade,
    id: GradeCalculationMethods.CalculatedGrade,
  },
  label: (
    <OptionLabel
      iconName="16/EditorTable"
      title={getCalibrationMethodTitle(GradeCalculationMethods.CalculatedGrade)}
      description="The grade given by the Line Manager and the Functional Manager will be combined and..."
      helpText="Use this if you..."
    />
  ),
}

export const calibrationLogicOptions: RadioOption<
  OptionInterface<GradeCalculationMethods>
>[] = [
  lMRecommendationOption,
  fMRecommendationOption,
  scorecardGradesOption,
  combinedRecommendationsOption,
  combinedGradesOption,
]

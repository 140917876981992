import React from 'react'
import { notReachable } from '@src/utils/notReachable'
import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
  ReviewCycleStage,
} from '@src/interfaces/reviewCycles'
import { ReviewActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/ReviewActions'
import { CalibrationActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/CalibrationActions'
import { GoalsActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/GoalsActions'
import { PublishActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/PublishActions'
import { cycleModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/CycleModel'

interface Props {
  cycle: ReviewCyclesInterface
  stages: ReviewCycleStage[]
  eligibleGroups: EligibleGroupInterface[]
  stage: ReviewCycleStage
}

export const StageActions = ({ cycle, stages, eligibleGroups, stage }: Props) => {
  const isSwitchStageActionVisible =
    cycleModel.isManual(cycle) && cycleModel.getCurrentStage(cycle) === stage

  switch (stage) {
    case ReviewCycleStage.Review:
      return (
        <ReviewActions
          cycle={cycle}
          stages={stages}
          stage={stage}
          eligibleGroups={eligibleGroups}
          isStageSwitcherVisible={isSwitchStageActionVisible}
        />
      )
    case ReviewCycleStage.Calibration:
      return (
        <CalibrationActions
          cycle={cycle}
          stage={stage}
          isStageSwitcherVisible={isSwitchStageActionVisible}
        />
      )
    case ReviewCycleStage.TeamGoals:
    case ReviewCycleStage.DepartmentGoals:
      return (
        <GoalsActions
          cycle={cycle}
          stage={stage}
          isStageSwitcherVisible={isSwitchStageActionVisible}
        />
      )
    case ReviewCycleStage.ManagersPublish:
    case ReviewCycleStage.EmployeesPublish:
    case ReviewCycleStage.Completed:
      return isSwitchStageActionVisible ? (
        <PublishActions cycle={cycle} stage={stage} />
      ) : null
    default:
      return notReachable(stage)
  }
}

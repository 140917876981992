import React, { useEffect } from 'react'
import PageLoading from '@components/PageLoading/PageLoading'
import { GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM } from '@src/constants/api'
import {
  connectGoogleEmail,
  connectGoogleCalendar,
  OfficeSuiteProviderSourceType,
  OfficeSuiteProviderType,
  connectMicrosoftEmail,
  connectMicrosoftCalendar,
  connectGoogleWorkspace,
  connectSlackWorkspace,
  connectJiraWorkspace,
  connectLookerWorkspace,
  connectClickupWorkspace,
} from '@src/api/officeSuiteProvider'
import * as Sentry from '@sentry/react'
import { LocalStorageKeys } from '@src/store/auth/types'
import { UNKNOWN_ERROR } from '@src/pages/Forms/QueryForm/constants'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { CreateEmployeeUploadSource } from '@src/api/bulkDataImport'
import {
  clickupIntegrationPreferences,
  jiraPreferencesRequests,
  lookerIntegrationPreferences,
} from '@src/api/integrations'
import { roadmapSettings } from '@src/api/settings'

declare global {
  interface Window {
    createEmployeeUploadFromWorkspace?: (type: CreateEmployeeUploadSource) => void
  }
}

const GrantPermissionsRedirect = () => {
  useEffect(() => {
    const validateToken = async () => {
      const currentURL = new URL(window.location.href)
      const token = currentURL.searchParams.get('code')
      const state = currentURL.searchParams.get('state')
      let source: OfficeSuiteProviderSourceType | undefined
      let type: OfficeSuiteProviderType | undefined

      try {
        const parsedState: unknown = state && JSON.parse(state)

        if (typeof parsedState === 'object' && parsedState !== null) {
          if ('source' in parsedState) {
            source = parsedState.source as OfficeSuiteProviderSourceType | undefined
          }

          if ('type' in parsedState) {
            type = parsedState.type as OfficeSuiteProviderType | undefined
          }
        }
      } catch (e) {
        Sentry.captureException(e)
        source = undefined
        type = undefined
      }

      try {
        if (token) {
          switch (type) {
            case 'googleEmail': {
              await connectGoogleEmail(
                token,
                GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
                source,
              )
              break
            }

            case 'googleCalendar': {
              await connectGoogleCalendar(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              break
            }

            case 'googleWorkspace': {
              await connectGoogleWorkspace(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              break
            }

            case 'slackWorkspace': {
              await connectSlackWorkspace(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              break
            }

            case 'microsoftEmail': {
              await connectMicrosoftEmail(
                token,
                GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
                source,
              )
              break
            }

            case 'jiraWorkspace': {
              await connectJiraWorkspace(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              await jiraPreferencesRequests.update({ enabled: true }, { id: '1' })
              await roadmapSettings.update({ jira_epics_enabled: true }, { id: '1' })
              workspaceLocalStorage.setItem(
                LocalStorageKeys.CONNECTED_INTEGRATION,
                'Jira',
              )
              break
            }
            case 'lookerWorkspace': {
              await connectLookerWorkspace(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              await lookerIntegrationPreferences.update({ enabled: true }, { id: '1' })
              workspaceLocalStorage.setItem(
                LocalStorageKeys.CONNECTED_INTEGRATION,
                'Looker',
              )
              break
            }

            case 'microsoftCalendar': {
              await connectMicrosoftCalendar(
                token,
                GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
              )
              break
            }

            case 'clickupWorkspace': {
              await connectClickupWorkspace(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              await clickupIntegrationPreferences.update({ enabled: true }, { id: '1' })
              await roadmapSettings.update({ clickup_enabled: true }, { id: '1' })
              workspaceLocalStorage.setItem(
                LocalStorageKeys.CONNECTED_INTEGRATION,
                'ClickUp',
              )
              break
            }

            default: {
              Sentry.captureMessage(
                '[GrantPermissionsRedirect] type is required but not defined',
              )
            }
          }
        }
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            type,
          },
        })

        // save the error in the localstorage as we close the window
        workspaceLocalStorage.setItem(
          LocalStorageKeys.GOOGLE_PERMISSIONS_ERROR,
          JSON.stringify({
            type,
            message: e?.response?.data?.[0] || UNKNOWN_ERROR,
          }),
        )
      } finally {
        if (window && window.opener && window.opener.location) {
          if (type === 'googleWorkspace') {
            window.opener?.focus?.()
            window.opener.createEmployeeUploadFromWorkspace?.('google')
          } else if (type === 'slackWorkspace') {
            window.opener?.focus?.()
            window.opener.createEmployeeUploadFromWorkspace?.('slack')
          } else if (type === 'jiraWorkspace') {
            window.opener?.focus?.()
            window.opener.createEmployeeUploadFromWorkspace?.('jira')
          } else {
            window.opener.location.reload()
          }
          window.close()
        }
      }
    }

    validateToken()
  }, [])

  return <PageLoading />
}

export default GrantPermissionsRedirect

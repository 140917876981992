import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { useNavigation } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useNavigation'
import { SwitchStageAction } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/SwitchStage/SwitchStageAction'

interface Props {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
  isStageSwitcherVisible: boolean
}

export const CalibrationActions = ({ cycle, stage, isStageSwitcherVisible }: Props) => {
  const { toPerformanceSummary } = useNavigation()

  return (
    <>
      <MoreBar.Action useIcon="16/ListBullet" onClick={toPerformanceSummary(cycle)}>
        Performance summary
      </MoreBar.Action>
      {isStageSwitcherVisible && <SwitchStageAction cycle={cycle} stage={stage} />}
    </>
  )
}

import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { Props as StageStepProps } from './StageProgressStep'
import { ProgressStepState } from '@revolut/ui-kit'
import { timelineModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/TimelineModel'
import { stageToLabelMap } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/constants'
import { formatStagePeriod } from '@src/pages/Forms/ReviewCycle/ReviewCycle/formatters/formatStagePeriod'

const getState = (
  cycle: ReviewCyclesInterface,
  stage: ReviewCycleStage,
  availableStages: ReviewCycleStage[],
): ProgressStepState => {
  if (timelineModel.isFinishedStage(cycle, stage, availableStages)) {
    return 'done'
  }

  if (timelineModel.isOngoingStage(cycle, stage)) {
    return 'pending'
  }

  return 'default'
}

interface Props extends Omit<StageStepProps, 'onClick'> {
  key: ReviewCycleStage
}

export const adaptVisibleStagesProps = (
  cycle: ReviewCyclesInterface,
  selectedStage: ReviewCycleStage,
  stages: ReviewCycleStage[],
): Props[] =>
  stages.reduce<Props[]>((visibleStagesProps, stage) => {
    if (stage === ReviewCycleStage.EmployeesPublish) {
      visibleStagesProps.push(adaptCompletedStage(cycle, selectedStage, stages))
    } else if (stage === ReviewCycleStage.Completed) {
      return visibleStagesProps
    } else {
      visibleStagesProps.push({
        key: stage,
        title: stageToLabelMap[stage],
        description: formatStagePeriod(cycle, stage),
        state: getState(cycle, stage, stages),
        highlight: selectedStage === stage,
      })
    }

    return visibleStagesProps
  }, [])

const adaptCompletedStage = (
  cycle: ReviewCyclesInterface,
  selectedStage: ReviewCycleStage,
  stages: ReviewCycleStage[],
): Props => {
  const isCompleted =
    timelineModel.getCurrentStage(cycle, stages) === ReviewCycleStage.Completed

  const visibleStage = isCompleted
    ? ReviewCycleStage.Completed
    : ReviewCycleStage.EmployeesPublish
  const isHighlighted = [
    ReviewCycleStage.Completed,
    ReviewCycleStage.EmployeesPublish,
  ].includes(selectedStage)
  const state = isCompleted ? 'done' : getState(cycle, visibleStage, stages)

  return {
    key: visibleStage,
    title: stageToLabelMap[visibleStage],
    description: formatStagePeriod(cycle, visibleStage),
    state,
    highlight: isHighlighted,
  }
}

import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Avatar, Flex, IconName, Item, Text, Token } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'
import { AvatarType } from '@src/interfaces/employees'
import { getAvatarUrl } from '@src/utils/employees'

type Props = {
  firstName?: string
  lastName?: string
  text?: ReactNode | ReactNode[]
  label?: string
  avatar?: AvatarType
  status?: Statuses | null
  fullName?: string
  icon?: IconName
  noItemWrap?: boolean
}

const Wrap = styled(Item)`
  padding: 0;
`

const AvatarSnippet = ({
  firstName,
  lastName,
  text,
  label,
  avatar,
  status,
  fullName,
  icon,
  noItemWrap = false,
}: Props) => {
  const getBadge = () => {
    if (!status) {
      return null
    }

    switch (status) {
      case Statuses.completed:
      case Statuses.completed_late:
      case Statuses.approved:
        return (
          <Avatar.Badge bg={Token.color.accent} position="bottom-right" useIcon="Check" />
        )
      case Statuses.rejected:
        return (
          <Avatar.Badge bg={Token.color.red} position="bottom-right" useIcon="Cross" />
        )
      case Statuses.expired:
        return (
          <Avatar.Badge
            bg={Token.color.greyTone50}
            position="bottom-right"
            useIcon="Cross"
          />
        )
      default:
        return (
          <Avatar.Badge
            bg={Token.color.orange}
            position="bottom-right"
            useIcon="16/SandWatch"
          />
        )
    }
  }

  const renderInitials = () => {
    if (icon || avatar) {
      return null
    }

    if (firstName && lastName) {
      return (
        <>
          {firstName[0]}
          {lastName[0]}
        </>
      )
    }

    if (fullName) {
      return fullName[0]
    }

    return null
  }

  if (noItemWrap) {
    return (
      <Flex alignItems="center" gap="s-16">
        <Avatar image={getAvatarUrl(avatar)} useIcon={icon}>
          {renderInitials()}
          {getBadge()}
        </Avatar>
        <Flex flexDirection="column">
          <Flex gap="s-4" alignItems="flex-end">
            <Text variant="primary" color={Token.color.foreground}>
              {fullName}
            </Text>
            {label && (
              <Text color={Token.color.greyTone50} fontWeight={400}>
                {label}
              </Text>
            )}
          </Flex>
          {text && <Text variant="caption">{text}</Text>}
        </Flex>
      </Flex>
    )
  }

  return (
    <Wrap>
      <Item.Avatar>
        <Avatar image={getAvatarUrl(avatar)} useIcon={icon}>
          {renderInitials()}
          {getBadge()}
        </Avatar>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          {fullName}{' '}
          {label && (
            <Text color={Token.color.greyTone50} fontWeight={400}>
              {label}
            </Text>
          )}
        </Item.Title>
        {text && <Item.Description>{text}</Item.Description>}
      </Item.Content>
    </Wrap>
  )
}

export default AvatarSnippet

import {
  GetRequestData,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import {
  CRMInterface,
  CRMReview,
  CRMReviewResult,
  ReviewResult,
  SendEngagementEmailInterface,
} from '@src/interfaces/CRM'
import { API } from '@src/constants/api'
import { api, apiWithoutHandling } from '@src/api/index'
import { filterSortPageIntoQuery, groupFiltersIntoURLQuery } from '@src/utils/table'
import { AxiosResponse } from 'axios'
import { FileInterface } from '@src/interfaces/files'
import { isFile } from '@src/utils/files'
import { uploadFile } from '@src/api/files'
import { useTableReturnType } from '@src/components/Table/hooks'
import { SelectTableWrapperOnChangeData } from '@src/components/TableV2/AdvancedCells/SelectCell/SelectTableWrapper'
import { pathToUrl } from '@src/utils/router'
import { getBulkIds } from '@src/hooks/useFetchBulkIds'
import { useFetch } from '@src/utils/reactQuery'
import { QueryClient } from 'react-query'

export const crmTableRequests: TableRequestInterface<CRMInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.CRM, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

const uploadAttachments = async (data: Partial<SendEngagementEmailInterface>) => {
  let files: AxiosResponse<FileInterface>[] | undefined
  if (data.attachments) {
    files = await Promise.all(
      data.attachments.map(file =>
        isFile(file)
          ? uploadFile(file, 'candidate_email')
          : new Promise<AxiosResponse<FileInterface>>(resolve => {
              resolve({ data: file } as AxiosResponse<FileInterface>)
            }),
      ),
    )
  }
  return files
}

export const bulkEngagementEmailsRequests: RequestInterfaceNew<SendEngagementEmailInterface> =
  {
    get: async ({ id }) => api.get(`${API.INTERVIEWS}/candidates/${id}/emails`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.INTERVIEWS}/candidates/${id}/emails`, data),
    submit: async ({ add_booking_link, calendar, ...data }) => {
      const files = await uploadAttachments(data)
      // if there's no booking link the flow is the same as the candidate bulk emails
      const endpoint = add_booking_link
        ? '/candidateBulkScheduling'
        : '/candidateBulkEmails'
      const commonPayload = {
        ...data,
        attachments:
          files?.map(file => ({
            id: file?.data?.id,
          })) || [],
      }
      const payload = add_booking_link
        ? {
            ...commonPayload,
            calendar_id: calendar?.calendar_id,
            calendar_name: calendar?.summary,
          }
        : commonPayload
      return apiWithoutHandling.post<SendEngagementEmailInterface>(
        endpoint,
        payload as SendEngagementEmailInterface,
      )
    },
  }

export const getCRMIds = (
  table: useTableReturnType<CRMInterface>,
  selectedData?: SelectTableWrapperOnChangeData<CRMInterface>,
) => getBulkIds<CRMInterface>(API.CRM, table.filterBy, table.data, selectedData)

export const createReview = (table: useTableReturnType<CRMInterface>, ids: number[]) => {
  return apiWithoutHandling.post<CRMReview>(pathToUrl(API.CRM_REVIEWS), {
    interview_round_ids: ids,
    filters: groupFiltersIntoURLQuery(table.filterBy),
  })
}

export const useGetCRMReview = (reviewId?: string | number) =>
  useFetch<CRMReview>(reviewId ? pathToUrl(API.CRM_REVIEWS, { reviewId }) : null)

export const useGetReviewResult = (
  reviewId?: string | number,
  interviewRoundId?: string | number,
) =>
  useFetch<GetRequestData<CRMReviewResult>>(
    reviewId ? pathToUrl(API.CRM_INTERVIEW_ROUND_REVIEW, { reviewId }) : null,
    undefined,
    reviewId
      ? {
          params: {
            interview_round: interviewRoundId,
          },
        }
      : undefined,
  )

export const invalidateGetReviewResult = (queryClient: QueryClient, reviewId?: number) =>
  queryClient.invalidateQueries(pathToUrl(API.CRM_INTERVIEW_ROUND_REVIEW, { reviewId }))

export const createReviewResult = (
  reviewId: string | number,
  interviewRoundId: string | number,
  reviewResult: ReviewResult,
) =>
  apiWithoutHandling.post(pathToUrl(API.CRM_INTERVIEW_ROUND_REVIEW, { reviewId }), {
    interview_round: {
      id: interviewRoundId,
    },
    review_result: reviewResult,
  })

export const updateReviewResult = (
  reviewId: string | number,
  interviewRoundId: string | number,
  interviewRoundReviewId: string | number,
  reviewResult: ReviewResult,
) =>
  apiWithoutHandling.patch(
    pathToUrl(API.CRM_INTERVIEW_ROUND_REVIEW, { reviewId, interviewRoundReviewId }),
    {
      interview_round: {
        id: interviewRoundId,
      },
      review_result: reviewResult,
    },
  )

export const bulkConsiderForAJob = (candidateIds: number[], jobPostingId: number) =>
  apiWithoutHandling.post(API.CANDIDATES_BULK_SOURCING, {
    candidate_ids: candidateIds,
    job_posting: {
      id: jobPostingId,
    },
  })

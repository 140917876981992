import React, { useEffect, useState } from 'react'
import { connect } from 'lape'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, InputGroup, Text, Token, VStack, Widget } from '@revolut/ui-kit'

import { navigateReplace } from '@src/actions/RouterActions'
import { dynamicGroups } from '@src/api/dynamicGroups'
import { validatePayGroup } from '@src/api/payrollV2'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageActions } from '@src/components/Page/PageActions'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { TableFilter } from '@src/features/AudienceSelection/AudienceTable'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PayGroupInterface } from '@src/interfaces/payrollV2'
import RadioSwitch from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { selectUser } from '@src/store/auth/selectors'
import { pathToUrl } from '@src/utils/router'
import { getDefaultFieldSetter } from '@src/utils/setDefaultField'
import { EligibilityConfig } from './EligibilityConfig'
import { formatValuesToSubmit, useFormatNonCompliantToIdAndName } from './helpers'
import { PayrunScheduleWidget } from './PayrunScheduleWidget'

const PAY_DATE_CONF_DEFAULT = { id: 'default' as const, name: 'Default' }
const PAY_DATE_CONF_CUSTOM = { id: 'custom' as const, name: 'Custom' }

type PayDateConfigType = typeof PAY_DATE_CONF_DEFAULT | typeof PAY_DATE_CONF_CUSTOM

export const PayGroupEditDetails = connect(() => {
  const params = useParams()
  const user = useSelector(selectUser)
  const { values } = useLapeContext<PayGroupInterface>()

  const [customFilters, setCustomFilters] = useState<TableFilter>({})
  const [payDateConfig, setPayDateConfig] =
    useState<PayDateConfigType>(PAY_DATE_CONF_DEFAULT)

  useFormatNonCompliantToIdAndName()

  const setDefault = getDefaultFieldSetter<PayGroupInterface>(values)

  useEffect(() => {
    setDefault('pay_frequency', { id: 'monthly', name: 'Monthly' })
    setDefault('pay_date_schedule', { id: 'current_month', name: 'Current Month' })
    setDefault('cut_off_date_trigger_number_of_days', '5')
    setDefault('cut_off_date_trigger', {
      id: 'after_pay_cycle_starts',
      name: 'After the pay cycle starts',
    })
    setDefault('pay_date_day', { id: 25, name: '25th' })
    setDefault('pay_date_lands_on_weekend', {
      id: 'pay_on_the_previous_business_day',
      name: 'Pay on the previous business day',
    })
  }, [])

  return (
    <>
      <VStack space="s-16" pb="s-72">
        <LapeNewInput name="name" label="Pay group name" required />
        <Box>
          <SectionTitle title="What payroll provider should be used for this pay group?" />
          <LapeRadioSelectInput
            name="payroll_provider"
            selector={selectorKeys.payroll_providers}
            label="Choose your payroll provider"
            required
          />
        </Box>
        <Box>
          <SectionTitle
            title="How often are your employees paid?"
            subtitle="Pay, or salary frequency, describes how often employees get paid"
          />
          <LapeRadioSelectInput
            required
            name="pay_frequency"
            label="Pay frequency"
            selector={() => Promise.resolve([{ id: 'monthly', name: 'Monthly' }])}
            searchable={false}
          />
        </Box>
        <Box>
          <SectionTitle
            title="When does your pay run start?"
            subtitle="Define which day of the month the pay run begins every month."
          />
          <LapeRadioSelectInput
            name="pay_period_start_day"
            selector={selectorKeys.month_days}
            label="Period start date (day of month)"
            required
          />
        </Box>
        <Box>
          <SectionTitle
            title="Pay date and cut off configuration"
            subtitle="Cut off dates allows you to set a deadline for change tracking for each pay run. This means there is some time between the last day of changes and when you pay employees."
          />
          <RadioSwitch<PayDateConfigType>
            variant="horizontal"
            value={payDateConfig}
            onChange={newValue => {
              setPayDateConfig(newValue)
            }}
            options={[
              {
                id: PAY_DATE_CONF_DEFAULT.id,
                label: PAY_DATE_CONF_DEFAULT.name,
                description: 'Use default values',
                value: PAY_DATE_CONF_DEFAULT,
              },
              {
                id: PAY_DATE_CONF_CUSTOM.id,
                label: PAY_DATE_CONF_CUSTOM.name,
                description: 'Configure',
                value: PAY_DATE_CONF_CUSTOM,
              },
            ]}
          />
        </Box>
        {payDateConfig.id === 'custom' && (
          <>
            <LapeRadioSelectInput
              name="pay_date_schedule"
              label="Pay date schedule"
              selector={selectorKeys.pay_date_schedules}
            />
            <Widget p="s-16">
              <VStack space="s-16">
                <Text color={Token.color.greyTone50} variant="emphasis2">
                  Cut off date (deadline for changes)
                </Text>
                <InputGroup variant="horizontal" data-testid="cut_off_date_section">
                  <Box maxWidth={320}>
                    <LapeNewInput
                      required
                      type="number"
                      name="cut_off_date_trigger_number_of_days"
                      label="Number of business days"
                    />
                  </Box>
                  <LapeRadioSelectInput
                    name="cut_off_date_trigger"
                    label="Trigger"
                    selector={selectorKeys.pay_group_triggers}
                  />
                </InputGroup>
              </VStack>
            </Widget>
            <Widget p="s-16">
              <VStack space="s-16">
                <Text color={Token.color.greyTone50} variant="emphasis2">
                  Pay date
                </Text>
                <InputGroup variant="horizontal" data-testid="cut_off_date_section">
                  <Box maxWidth={320}>
                    <LapeRadioSelectInput
                      required
                      name="pay_date_day"
                      selector={selectorKeys.month_days}
                      label="Pay date (day of month)"
                    />
                  </Box>
                  <LapeRadioSelectInput
                    name="pay_date_lands_on_weekend"
                    label="If pay date lands on weekend or holiday"
                    selector={selectorKeys.pay_date_lands_on_weekend_choices}
                  />
                </InputGroup>
              </VStack>
            </Widget>
          </>
        )}
        <PayrunScheduleWidget />
        <EligibilityConfig
          customFilters={customFilters}
          setCustomFilters={setCustomFilters}
        />
      </VStack>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          successText="Your changes were successfully validated"
          onAfterSubmit={() => {
            navigateReplace(pathToUrl(ROUTES.APPS.PAYROLL_V2.PAY_GROUP.CONFIRM, params))
          }}
          onClick={async () => {
            if (values.eligibility_type?.id === 'employee_filters') {
              if (values.eligibility_group?.id) {
                await dynamicGroups.update(
                  { table_filters: customFilters },
                  { id: values.eligibility_group.id },
                )
              } else {
                const newGroup = await dynamicGroups.submit(
                  {
                    name: `Created automatically for ${values.name} ${
                      values.id || Math.floor(Math.random() * 1000000)
                    } pay group`,
                    owner: { id: user.id, name: user.full_name },
                    visibility: { id: 'nobody', name: 'Nobody' },
                    audience_selection_type: {
                      id: 'filtered_table',
                      name: 'Table with filters',
                    },
                    table_filters: customFilters,
                  },
                  {},
                )
                values.eligibility_group = {
                  id: String(newGroup.data.id),
                  name: newGroup.data.name,
                }
              }
            }
            return validatePayGroup(formatValuesToSubmit(values))
          }}
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
})

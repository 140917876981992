import React, { useMemo } from 'react'
import { Flex, Icon, HStack, Text, Widget } from '@revolut/ui-kit'
import { EmployeeTotalCompensationEquityTimeSeriesInterface } from '@src/interfaces/totalCompensation'
import Tooltip from '@components/Tooltip/Tooltip'
import { BarChart } from '../Charts/BarChart'
import { useBarProjectedData } from '../helpers'
import { MIN_HEIGHT } from './EquityTotal'

type Props = {
  data?: EmployeeTotalCompensationEquityTimeSeriesInterface[]
  isLoading: boolean
}

export const EquityGranted = ({ data, isLoading }: Props) => {
  const dataKeys = useMemo(() => {
    if (!data) {
      return []
    }

    const allGrantsCategories = data.flatMap(item => item.grants)
    const uniqueGrantsCategories = [
      ...new Set(allGrantsCategories.map(item => item.category)),
    ]

    return uniqueGrantsCategories.map((category, index) => ({
      id: index,
      label: category,
      value: category,
    }))
  }, [data])

  const chartData = useMemo(() => {
    if (!data) {
      return []
    }

    return data
      .filter(value => value.grants.length)
      .map(value => {
        const grants = value.grants.reduce(
          (o, key) => ({ ...o, [key.category]: key.amount }),
          {},
        )

        return {
          ...value,
          ...grants,
          total: value.grant_total,
          label: '',
          tooltip: '',
        }
      })
  }, [data])

  const projectedDataIdx = useBarProjectedData(chartData)

  if (!isLoading && !data?.length) {
    return null
  }

  return (
    <Widget
      height="50vh"
      minHeight={MIN_HEIGHT}
      p="s-16"
      data-testid="granted_equity_section"
    >
      <Flex flexDirection="column" height="100%">
        <HStack gap="s-4">
          <Text variant="emphasis2">Granted equity</Text>
          <Tooltip
            placement="bottom"
            text="The total equity awarded to you, including both vested and unvested shares."
          >
            <Icon name="InfoOutline" size={16} />
          </Tooltip>
        </HStack>

        <BarChart
          data={chartData}
          dataKeys={dataKeys}
          isLoading={isLoading}
          projectedDataIdx={projectedDataIdx}
        />
      </Flex>
    </Widget>
  )
}

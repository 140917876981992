import { PUBLIC_PREFIX, ROUTES, SIGNUP, WORKSPACES } from '@src/constants/routes'

const notAllowedWorkspaces = [
  WORKSPACES.MAIN,
  WORKSPACES.FIND,
  ROUTES.MAIN,
  PUBLIC_PREFIX,
  SIGNUP,
  ROUTES.LOGIN.MAIN,
  ROUTES.GRANT_PERMISSIONS_REDIRECT,
  ROUTES.CONTACT_US,
]

export const getWorkspaceFromPathname = () => {
  const pathname = window.location.pathname
  const secondSubrouteIndex = pathname.indexOf('/', 1)
  const firstSubroute =
    pathname.substring(0, secondSubrouteIndex > 0 ? secondSubrouteIndex : undefined) ||
    '/'
  return notAllowedWorkspaces.includes(firstSubroute)
    ? undefined
    : firstSubroute.replace('/', '').toLowerCase()
}

import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { useSidebarContext } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/SidebarProvider'
import { SwitchStageAction } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/SwitchStage/SwitchStageAction'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'

interface Props {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
  isStageSwitcherVisible: boolean
}

export const GoalsActions = ({ cycle, stage, isStageSwitcherVisible }: Props) => {
  const { show } = useSidebarContext()

  return (
    <>
      <MoreBar.Action useIcon="LinkExternal" onClick={show}>
        Review goals
      </MoreBar.Action>
      {isStageSwitcherVisible && <SwitchStageAction cycle={cycle} stage={stage} />}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import {
  InputGroup,
  Text,
  Avatar,
  Spacer,
  Token,
  HStack,
  VStack,
  Box,
  Item,
  IconButton,
  Icon,
  Flex,
  Widget,
  ActionButton,
} from '@revolut/ui-kit'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { LocalStorageKeys, PermissionTypes } from '@src/store/auth/types'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JiraPreferencesInterface } from '@src/interfaces/integrations'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { OnboardingIntro } from '@src/components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@src/components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroTips } from '@src/components/OnboardingIntro/OnboardingIntroTips'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { CollapsibleRadioSwitch } from '@src/pages/Settings/common/CollapsibleRadioSwitch'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { IntegrationEnablePopup } from '../components/IntegrationEnablePopup'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

const getLabel = (url: string) => {
  return new URL(url).hostname
}

export const JiraForm = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const { values, submit, isSubmitting, loading, reset } =
    useLapeContext<JiraPreferencesInterface>()
  const confirmationPopup = useConfirmationDialog()
  const showStatusPopup = useShowStatusPopup()
  const [enableIntegrationPopupOpen, setEnableIntegrationPopupOpen] = useState(false)

  const disableEdit = !permissions.includes(PermissionTypes.ChangeJiraIntegration)

  useEffect(() => {
    const integrationConnected = workspaceLocalStorage.getItem(
      LocalStorageKeys.CONNECTED_INTEGRATION,
    )
    if (integrationConnected) {
      showStatusPopup({
        status: 'success',
        title: `${integrationConnected} integration successful`,
      })
      workspaceLocalStorage.removeItem(LocalStorageKeys.CONNECTED_INTEGRATION)
    }
  }, [])

  return (
    <PageWrapper>
      <PageHeader
        title="Jira"
        backUrl={pathToUrl(ROUTES.FEATURES.INTEGRATIONS)}
        subtitle={
          <VStack maxWidth={{ all: '100%', md: Token.breakpoint.md }} pb="s-4">
            <HStack align="end">
              <VStack>
                {values.enabled && (
                  <Text variant="emphasis1" color={Token.color.success}>
                    Connected
                  </Text>
                )}
                <Text color={Token.color.greyTone50} variant="body2">
                  Collaboration
                </Text>
              </VStack>
              <Spacer />
              <Avatar useIcon="Jira|image" size={56}>
                {values.enabled ? (
                  <Avatar.Badge useIcon="Check" bg={Token.color.success} />
                ) : null}
              </Avatar>
            </HStack>
          </VStack>
        }
      />

      {!values.enabled && (
        <Box pt="s-16">
          <ActionButton
            useIcon="Link"
            pending={loading}
            disabled={loading || isSubmitting}
            variant="accent"
            onClick={async evnt => {
              evnt.preventDefault()
              setEnableIntegrationPopupOpen(true)
            }}
          >
            Enable
          </ActionButton>
        </Box>
      )}

      <PageBody mb="s-64" mt="s-16" gap="s-16">
        <OnboardingIntro videoPosterUrl="/integrations/Integration_Jira.png">
          {values.enabled && (
            <Item>
              <Item.Avatar>
                <Avatar label={getLabel(values.server)} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{values.server}</Item.Title>
                <Item.Description>Connected server</Item.Description>
              </Item.Content>
              <Item.Side>
                <IconButton
                  useIcon="Pencil"
                  color={Token.color.accent}
                  onClick={evnt => {
                    evnt.preventDefault()
                    setEnableIntegrationPopupOpen(true)
                  }}
                  disabled={disableEdit}
                />
              </Item.Side>
            </Item>
          )}
          <OnboardingIntroTextWidget
            title={
              <FormattedMessage
                id="integrations.jira.intro.title"
                defaultMessage="About integration"
              />
            }
          >
            <p>
              <FormattedMessage
                id="integrations.jira.intro.text"
                defaultMessage="Connect your Jira projects and automatically track roadmap progress within Revolut People Performance app. See real-time updates on issue status, identify potential roadblocks, and stay on top of your project's overall health"
              />
            </p>
          </OnboardingIntroTextWidget>
        </OnboardingIntro>
        <OnboardingIntroTips
          title={
            <FormattedMessage
              id="integrations.jira.intro.tips.title"
              defaultMessage="How to use"
            />
          }
          items={[
            {
              title: (
                <FormattedMessage
                  id="integrations.jira.intro.tips.sync.title"
                  defaultMessage="Enable Jira integration for your team or department"
                />
              ),
              icon: 'Gear',
              text: (
                <FormattedMessage
                  id="integrations.jira.intro.tips.sync.text"
                  defaultMessage="Go to <a>Goals Settings -> Roadmaps</a> and switch on “Enable Roadmaps” option. And then “Manage Goals and Roadmaps based on Jira”"
                  values={{
                    a: chunks => (
                      <InternalLink to={ROUTES.SETTINGS.GOALS.ROADMAPS}>
                        {chunks}
                      </InternalLink>
                    ),
                  }}
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="integrations.jira.intro.tips.roadmap.title"
                  defaultMessage="Attach roadmap to your team or department"
                />
              ),
              icon: 'Target',
              text: (
                <FormattedMessage
                  id="integrations.jira.intro.tips.roadmap.text"
                  defaultMessage="Team and department owners can attach roadmaps to their units. For team, functions and department roadmaps, on the Summary tab, click on the Edit on details section. From there, scroll until 4. Integrations and add the relevant Jira Projects."
                />
              ),
            },
          ]}
        />
        {values.enabled && (
          <>
            <HideIfCommercial>
              <Widget>
                <CollapsibleRadioSwitch
                  itemTypeProps={{
                    title: 'Enable HR tickets integration',
                    description: 'Add ability to create HR tickets.',
                  }}
                  name="create_hr_tickets_enabled"
                  disabled={disableEdit}
                >
                  <InputGroup>
                    <LapeNewInput
                      required
                      name="hr_project_key"
                      label="HR project key"
                      message="Used to create tickets in HR Jira board"
                      disabled={disableEdit}
                    />
                    <LapeNewInput
                      required
                      name="hrsd_project_key"
                      label="HR service desk project key"
                      message="Used to create service desk tickets for HR"
                      disabled={disableEdit}
                    />
                  </InputGroup>
                </CollapsibleRadioSwitch>
              </Widget>
            </HideIfCommercial>
            <Item
              color={Token.color.danger}
              use="button"
              disabled={disableEdit}
              onClick={async evnt => {
                evnt.preventDefault()
                confirmationPopup.show({
                  label: 'Are you sure?',
                  body: 'After disabling this integration you won’t be able to use Jira as a tracking tool',
                  onConfirm: async () => {
                    values.enabled = false
                    try {
                      const response = await submit()
                      showStatusPopup({ title: 'Integration disabled' })
                      reset(response)
                    } catch (error) {
                      showStatusPopup({
                        title: 'Cannot disable integration',
                        description: getStringMessageFromError(error),
                        status: 'error',
                      })
                      values.enabled = true
                    }
                  },
                })
              }}
            >
              <Item.Content>
                <Flex gap="s-16">
                  <Icon name="Unlink" />
                  <Item.Title>Disconnect Jira</Item.Title>
                </Flex>
              </Item.Content>
            </Item>
          </>
        )}
      </PageBody>

      {/* Only form in that page is when we can edit HR jira ticket */}
      <HideIfCommercial>
        <PageActions>
          <NewSaveButtonWithPopup
            successText="Settings saved successfully"
            onAfterSubmit={() => {
              queryClient.invalidateQueries(API.JIRA_INTEGRATION_PREFERENCES)
            }}
            useValidator
          />
        </PageActions>
      </HideIfCommercial>
      <IntegrationEnablePopup
        isOpen={enableIntegrationPopupOpen}
        type={'jira'}
        onClose={() => {
          setEnableIntegrationPopupOpen(false)
        }}
      />
    </PageWrapper>
  )
}

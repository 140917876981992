import isEmpty from 'lodash/isEmpty'

export const getDefaultFieldSetter =
  <T extends object>(values: T) =>
  <K extends keyof T>(key: K, defaultValue: T[typeof key]) => {
    if (values && values[key] == null) {
      values[key] = defaultValue
    }
  }

export const getCopyDefaultField =
  <T extends object>(to: T, from: T | undefined) =>
  <K extends keyof T>(key: K) => {
    if (!from || !from[key] == null) {
      return
    }
    if (to && (to[key] == null || isEmpty(to[key]))) {
      to[key] = from[key]
    }
  }
